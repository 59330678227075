import React from "react"

export default function Footer() {
  return (
    <>
      <footer className="relative bg-gray-300 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "90px", transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                About
              </h4>
              <h5 className="text-xs mt-0 mb-2 text-gray-700 font-semibold">
                NuZeta Inc. is a Toronto based Web and Mobile apps development
                agency specializing in Ruby on Rails, React, React Native,
                NodeJS, and Javascript development.
              </h5>
              <div className="mt-6">
                {/* <button
                  className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
                <button
                  className="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-facebook-square"></i>
                </button>
                <button
                  className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-dribbble"></i>
                </button>
                <button
                  className="bg-white text-gray-900 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-github"></i>
                </button> */}
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full ml-auto">
                  <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                    Contact Info
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-700 hover:text-gray-700 font-semibold block pb-2 text-xs"
                        href="mailto:contact@nuzeta.ca"
                      >
                        EMAIL: contact@nuzeta.ca
                      </a>
                    </li>
                    <li className="text-gray-700 hover:text-gray-700 font-semibold block pb-2 text-xs">
                      PHONE: 855-6NU-ZETA (855-668-9382)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-400" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-600 font-semibold py-1">
                Copyright © {new Date().getFullYear()}{" "}
                <a
                  href="https://nuzeta.ca"
                  className="text-gray-600 hover:text-gray-900"
                >
                  NuZeta Inc.
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
